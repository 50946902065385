export const manualItems = [
  {
    id: 'manual-1',
    title: 'Hoe werkt de landelijke wedstrijd?',
    text: '<p>Het kan voorkomen dat je school geen beheerder heeft, omdat deze niet meer werkzaam is voor de school of zijn/haar account heeft verwijderd. In dit geval kun je het beste een e-mail sturen naar <a class="link-main">info@mediamasters.nl.</a></p><br/><p>Vermeld de schoolnaam, plaats en het e-mailadres van het Mijn MediaMasters-account van degene op school die de nieuwe beheerder voor MediaMasters wil worden. Wij zullen dan de beheerder voor je aanpassen.</p>',
  },
  {
    id: 'manual-2',
    title: 'Spelregels',
    text: '<p>Vermeld de schoolnaam, plaats en het e-mailadres van het Mijn MediaMasters-account van degene op school die de nieuwe beheerder voor MediaMasters wil worden. Wij zullen dan de beheerder voor je aanpassen.</p>',
  },
  {
    id: 'manual-3',
    title: 'Puntentelling',
    text: '<p>Vermeld de schoolnaam, plaats en het e-mailadres van het Mijn MediaMasters-account van degene op school die de nieuwe beheerder voor MediaMasters wil worden. Wij zullen dan de beheerder voor je aanpassen.</p>',
  },
  {
    id: 'manual-4',
    title: 'Speldagen',
    text: '<p>Wij zullen dan de beheerder voor je aanpassen.</p>',
  },
  {
    id: 'manual-5',
    title: 'Overzicht: Wat kun je per speldag verwachten?',
    text: '<p>Wij zullen dan de beheerder voor je aanpassen.</p>',
  },
  {
    id: 'manual-6',
    title: 'MediaMasters Extra',
    text: '<p>Wij zullen dan de beheerder voor je aanpassen.</p>',
  },
  {
    id: 'manual-7',
    title: 'Mediaquiz (extra vragen)',
    text: '<p>Wij zullen dan de beheerder voor je aanpassen.</p>',
  },
  {
    id: 'manual-8',
    title: 'Het verhaal: ‘Jonge Uitvinders: The Battle’',
    text: '<p>Wij zullen dan de beheerder voor je aanpassen.</p>',
  },
]
