<template>
  <div class="video-player-wrapper" :style="cssVars" @click="togglePlay">
    <video ref="videoPlayer"
           :width="videoWidth"
           :height="videoHeight"
    >
      <source :src="source" type="video/mp4"/>
      Your browser does not support the video tag.
    </video>
    <img v-if="!playing" class="play-icon" :src="require('../../../assets/icons/icn_play_video.svg')"/>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/runtime-core'
export default {
  name: 'App',
  props: {
    source: {
      type: String,
      required: false,
      default: 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4'
    },
    videoWidth: {
      type: String,
      required: false,
      default: '320'
    },
    videoHeight: {
      type: String,
      required: false,
      default: '240'
    }
  },
  setup(props) {
    const videoPlayer = ref({})
    const playing = ref(false)

    function togglePlay() {
      if (playing.value) {
        videoPlayer.value.pause()
      } else {
        videoPlayer.value.play()
      }
      playing.value = !playing.value
    }
    return {
      playing,
      togglePlay,
      videoPlayer
    }
  },
  computed: {
    cssVars() {
      return {
        '--videoWidth': this.videoWidth + 'px',
        '--videoHeight': this.videoHeight + 'px',
      }
    },
  },
  methods: {
    stop() {
      const { videoPlayer } = this.$refs
      videoPlayer.pause()
      videoPlayer.currentTime = 0
    },
    setSpeed(speed) {
      this.$refs.videoPlayer.playbackRate = speed
    },
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.video-player-wrapper {
  position: relative;
  width: var(--videoWidth);
  height: var(--videoHeight);
  border-radius: rem(12);
  overflow: hidden;
}

.play-icon{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 60px;
  height: 60px;
}
</style>
